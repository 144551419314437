<template>
  <v-card>
    <Loader
      v-if="loading"
      :key="generateUniqueId()"
      :loader-state="loading"
    ></Loader>

    <v-card-title>Création d'un moteur</v-card-title>

    <v-card-text>
      <v-form
        v-model="valid"
        class="multi-col-validation mt-5"
        @submit.prevent="checkForm"
      >
        <h4 class="mb-5">
          Informations moteur
        </h4>

        <p v-if="errors.length">
          <v-alert
            v-for="(error, index) in errors"
            :key="index"
            text
            dense
            type="error"
          >
            {{ error.title }}
          </v-alert>
        </p>

        <Snackbar
          v-if="successMessage"
          :key="generateUniqueId()"
          :snackbar-state="snackbar"
          :success-message="successMessage"
        ></Snackbar>

        <v-row>
          <v-col
            cols="10"
            md="10"
          >
            <v-text-field
              v-model="name"
              :rules="nameRules"
              class="mb-5"
              label="Nom"
              outlined
              dense
              placeholder="Nom"
              hide-details
            ></v-text-field>

            <v-text-field
              v-model="engineId"
              :rules="engineIdRules"
              label="ID moteur"
              class="mb-5"
              outlined
              dense
              placeholder="ID moteur"
              hide-details
              type="number"
            ></v-text-field>

            <v-switch
              v-model="enabled"
              :label="'Etat : ' + (enabled === true ? 'activé' : 'désactivé')"
            ></v-switch>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-btn
              class="mt-5 mr-2"
              color="primary"
              :disabled="!valid"
              type="submit"
            >
              Valider
            </v-btn>

            <v-btn
              class="mt-5"
              text
              :to="{ name: 'engineList' }"
            >
              Retour
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from 'vue'
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import config from '../../config'
import router from '@/router'
import Snackbar from '@/components/Common/Snackbar'
import Loader from '@/components/Common/Loader'

export default {
  components: {
    Snackbar,
    Loader
  },
  data () {
    return {
      valid: false,
      errors: [],
      roles: [],
      enabled: false,
      snackbar: false,
      successMessage: null,
      loading: false,
      name: '',
      nameRules: [
        v => !!v || 'Le nom est obligatoire'
      ],
      engineId: null,
      engineIdRules: [
        v => !!v || 'L\'id du moteur est obligatoire'
      ],
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline
      }
    }
  },
  methods: {
    checkForm () {
      this.errors = []

      if (isNaN(this.engineId)) {
        this.errors.push({ title: 'L\'ID du moteur doit être un nombre' })

        return
      }

      if (this.valid) {
        this.postEngine()
      }
    },
    postEngine () {
      this.valid = false
      this.loading = true

      const data = {
        name: this.name,
        engineId: parseInt(this.engineId),
        enabled: this.enabled
      }

      Vue.prototype.$http
        .post(config.apiUrl + '/api/engines', data, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 201) {
            const engine = response.data

            this.errors = []
            this.snackbar = true
            this.successMessage = 'Moteur créé avec succès.'

            setTimeout(() => {
              router.push({ name: 'engineDetails', params: { engineId: engine.id } })
            }, 1500)
          }

          this.loading = false
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
          }

          this.valid = true
          this.loading = false
        })
    }
  }
}
</script>
